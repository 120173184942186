import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

export default function Badge(props){
    const { name, operator } = props;
    const getOperatorText = () => {
        if(!operator.loading && operator.original == null)
            return <span>-</span>
        else if(operator.loading)
            return <Spinner animation="border" variant="light" />
        else if(operator.original != null && operator.original.length > 0)
            return <span>{operator.original.length}</span>
    }
    return (
        <div className={`card mb-3 widget-content`} style={{ backgroundColor: '#0d5b87' }}>
            <div className="widget-content-wrapper text-white">
                <div className="widget-content-left">
                    <div className="widget-heading">{name}</div>
                    <div className="widget-subheading">Amount</div>
                </div>
                <div className="widget-content-right">
                    <div className="widget-numbers text-white">
                        { getOperatorText() }
                    </div>
                </div>
            </div>
        </div>
    );
}