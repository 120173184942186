const leads = {
    original: null,
    filtered: null,
    filters: [],
    selected: null,
    loading: false,
}

export default function(state = leads, action){
    switch(action.type){
        case 'SET_LEADS_LOADING': {
            return { ...state, loading: action.payload.loading };
        }
        case 'SET_LEADS': {
            return { ...state, original: action.payload.leads };
        }
        case 'REMOVE_ALL_LEAD_FILTERS': {
            return { ...state, filters: [], filtered: null };
        }
        default:
            return { ...state };
    }
}