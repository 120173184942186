import React from 'react';
import { connect } from 'react-redux';

import { changeMarkersToShow } from '../../../../actions';

import Button from 'react-bootstrap/Button';


const SelectedRepReturnButton = props => {
    const onReturnButtonClick = () => {
        if(props.typeOfMarkerToShow.includes('selected_rep'))
            return props.changeMarkersToShow('reps');
        return props.changeMarkersToShow('trainers');
    }
    if(props.typeOfMarkerToShow.includes('selected')){
        const message = props.typeOfMarkerToShow.includes('selected_rep') ? 'reps' : 'trainers';
        return(
            <Button onClick={ onReturnButtonClick }>{`Return to ${message}`}</Button>
        )
    }
    return null; 
}

const mapStateToProps = state => ({
    typeOfMarkerToShow: state.typeOfMarkerToShow,
});

const mapDispatchToProps = dispatch => ({
    changeMarkersToShow: typeOfMarker => { dispatch(changeMarkersToShow(typeOfMarker)) }
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectedRepReturnButton)