const typeOfMarkerToShow = 'reps';

export default function(state = typeOfMarkerToShow, action){
    switch(action.type){
        case 'CHANGE_MARKER': {
            return action.payload;
        }
        default:
            return state;
    }
}