const reps = {
    original: null,
    filtered: null,
    filters: {},
    selected: null,
    loading: false,
}

export default function(state = reps, action){
    switch(action.type){
        case 'SET_REPS_LOADING': {
            return { ...state, loading: action.payload.loading };
        }
        case 'SET_REPS': {
            return { ...state, original: action.payload.reps };
        }
        case 'SET_SELECTED_REP': {
            return { ...state, selected: action.payload.rep };
        }
        case 'SET_FILTERED_REPS': {
            return { ...state, filtered: action.payload.reps };
        }
        case 'ADD_REP_FILTER': {
            const filters = { ...state.filters, [action.payload.filter.name]: action.payload.filter.fn };
            return { ...state, filters };
        }
        case 'REMOVE_REP_FILTER': {
            const filters = { ...state.filters };
            delete filters[action.payload.filter];
            return { ...state, filters };
        }
        case 'REMOVE_ALL_REP_FILTERS': {
            return { ...state, filters: {} };
        }
        default:
            return state;
    }
}