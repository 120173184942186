import React, { useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";
import Collapse from 'react-bootstrap/Collapse';
import Button from 'react-bootstrap/Button';

import ProfileCard from '../../DashboardComponent/ProfileCard';

import { setSelectedTrainer, changeMarkersToShow } from '../../../../actions';

import './RepProfile.scoped.scss';

const RepProfile = props => {
    const { rep, trainers } = props;
    const [ collapseOpen, setCollapseOpen ] = useState(false);
    const name = rep.firstname + ' ' + rep.lastname;
    const imageSrc = `https://www.thebrokernetwork.com/Images/SiteImages/${rep.BVAgentId}/photo_signature.jpg`;
    const [ isPaused ] = rep.cellNumber.includes('*') ? [true, rep.cellNumber.split('*')[1]] : [false, rep.cellNumber];
    let statusClassName;
    if(rep.userStatus === 'Active' && !isPaused)
        statusClassName = 'badge badge-success mr-2';
    else if(isPaused){
        statusClassName = 'badge badge-danger mr-2';
        rep.userStatus = 'paused';
    }
    const getBranchRows = () => {
        let branches = [];
        for(const { id } of rep.branches ){
            branches.push(
                <li>
                    {id}
                </li>
            );
        }
        return (
            <div>
                <ul>
                    { branches }
                </ul>
            </div>
        );
    }
    const handleTrainerNameClick = e => {
        e.preventDefault();
        const trainer = trainers.find(trainer => trainer.name.toString().replace(',', ', ') === rep.trainer.name);
        if(!trainer) return;
        props.setSelectedTrainer(trainer);
        props.changeMarkersToShow('selected_trainer');
    }

    return(
        <Col lg={6} style={{ marginBottom: '20px' }}>
            <ProfileCard style={{ flexDirection: 'column' }}>
                <Col style={{ padding: '2%' }}>
                    <Row>
                        <Col lg={1}>
                            <div>
                                <img
                                    alt=''
                                    className='rounded-circle'
                                    style={ { height: '60px', width: '50px' }}
                                    src={ imageSrc }
                                />
                            </div>
                        </Col>
                        <Col lg={11}>
                            <div style={{ fontSize: '25px'}}>
                                <span>{name}</span>
                            </div>
                            <div>
                                <div className='badge badge-success mr-2' style={{ textTransform: 'none' }}>
                                    Date Activated: {rep.dateActivated !== null ? moment.utc(rep.dateActivated, 'YYYY-MM-DDTHH:mm:ss.SSS').format('MM/DD/YYYY') : 'Date not specified'}
                                </div>
                                <div className={statusClassName} style={{ textTransform: 'none' }}>
                                    Status: { rep.userStatus }
                                </div>
                                { rep.region && 
                                    <div className='badge badge-primary' style={{ textTransform: 'none' }}>
                                        Region: { rep.region }
                                    </div>
                                }                   
                            </div>
                            <div>
                                <span>AgentID: { rep.BVAgentId}</span>
                            </div>
                            <div>
                                { rep.trainer &&
                                <React.Fragment>
                                    <span style={{ marginRight: '2px' }}>Trainer:</span>
                                    <Button
                                        onClick={handleTrainerNameClick}
                                        className='replink'
                                        variant='link'
                                    >
                                        {rep.trainer.name}
                                    </Button> 
                                </React.Fragment>
                                }
                            </div>
                            <div>
                                <div onClick={ () => setCollapseOpen(!collapseOpen)}>
                                    <span>Zipcodes: ({rep.branches.length})</span>
                                    <i className={`metismenu-state-icon pe-7s-angle-${collapseOpen ? 'down': 'right'} caret-left`} />
                                </div>
                                <Collapse in={collapseOpen}>
                                    { getBranchRows() }
                                </Collapse>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </ProfileCard>
        </Col>
    );
}

const mapDispatchToProps = dispatch => ({
    changeMarkersToShow: typeOfMarker => dispatch(changeMarkersToShow(typeOfMarker)),
    setSelectedTrainer: trainer => dispatch(setSelectedTrainer(trainer)),
});

export default connect(null, mapDispatchToProps)(RepProfile);