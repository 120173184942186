import React from 'react';
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import { OktaAuth } from '@okta/okta-auth-js';

import { useHistory, withRouter, Route } from 'react-router-dom';

import Dashboard from './Main';
import Login from './Login';

const AppWithRouter = () => {

    const history = useHistory();

    const {
        REACT_APP_OKTA_CLIENT_ID: clientId,
        REACT_APP_OKTA_DOMAIN: oktaDomain,
    } = process.env;

    const redirectUri =  window.location.protocol + "//" + window.location.host + '/implicit/callback';

    const onAuthRequired = () => {
        return history.push('login')
    }
  
    const issuer = `${oktaDomain}/oauth2/default`;
    const oktaAuth = new OktaAuth({
        issuer,
        clientId,
        redirectUri,
    });

    return(
        <Security
            oktaAuth={oktaAuth}
            onAuthRequired={onAuthRequired}
            pkce={true}
        >
            <SecureRoute path='/' exact={true} component={ Dashboard } />
            <Route path='/login' render={() => <Login issuer={ issuer } />} />
            <Route path='/implicit/callback' component={ LoginCallback }/>
        </Security>
    );
}

export default withRouter(AppWithRouter);