import React from 'react';
import { connect } from 'react-redux';

import MapComponent from './MapComponent/MapComponent';
import FilteringComponent from './FilteringComponents';
import ProfileSelector from './Profiles/ProfileSelector';
import Badge from './Badge';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './Main.scoped.scss';

const trainersFilter = trainers => {
    if(trainers.original !== null){
        trainers.original = trainers.original.reduce((prevValue, currentValue) => {
            return [...prevValue, ...currentValue.agentIds];
        }, []);
    }
    return trainers;
}

const MainContainer = props => {
    const reps = { ...props.reps };
    const trainers = { ...props.trainers };
    const leads = { ...props.leads };
    return(
        <div className="app-main__outer">
            <div className="app-main__inner mx-xl-5">
                <Row>
                    <Col lg={4}>
                        <Badge 
                            name='Reps'
                            operator={reps}
                        />
                    </Col>
                    <Col lg={4}>
                        <Badge 
                            name='Leads'
                            operator={leads}
                        />
                    </Col>
                    <Col lg={4}>
                        <Badge 
                            name='Trainers'
                            operator={trainersFilter(trainers)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col
                        md={12}
                        lg={12}
                    >
                        <div className="card" style={{border: '1px solid #0d5b87'}}>
                            <div className="card-header-tab card-header bg-naf">
                                <div className="card-header-title text-white">
                                    <i className="header-icon lnr-apartment icon-gradient bg-love-kiss"> </i>
                                    Map
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="tab-content">
                                    <FilteringComponent />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <Row>
                <Col
                    className="mt-5"
                    lg={12} 
                >
                    <MapComponent />
                    <Col
                        className="mt-5"
                        lg={12}
                    >
                        <ProfileSelector />
                    </Col>
                </Col>
            </Row>
        </div>
    );
}

const mapStateToProps = state => ({
    reps: state.reps,
    leads: state.leads,
    trainers: state.trainers,
});

export default connect(mapStateToProps, null)(MainContainer);