import _ from 'lodash';
import React from "react";
import ReactDOMServer from 'react-dom/server';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';

import Col from 'react-bootstrap/Col';

import RepInfoWindowContent from './Markers/RepInfoWindow';
import BranchInfoWindowContent from './Markers/BranchInfoWindow';
import LeadInfoWindowContent from './Markers/LeadInfoWindow';
import TrainerInfoWindow from './Markers/TrainerInfoWindow';

import GoogleButton from './Controllers/Button';

import ConvexHullGrahamScan from '../../../lib/ConvexHullGrahamScan';

import { setSelectedRep, fetchReps, fetchLeads, fetchTrainers, setSelectedTrainer, changeMarkersToShow } from '../../../actions';

import './MapComponent.scss';


class GoogleMap extends React.Component {

    googleMapRef = React.createRef();

    constructor(props){
        super(props);
        this.state = {
            markers: null,
            markCluster: null,
        }
    }

    componentDidUpdate(prevProps, prevState){
        if( prevProps.typeOfMarkerToShow !== this.props.typeOfMarkerToShow && this.props.typeOfMarkerToShow === 'reps' ||
            this.props.typeOfMarkerToShow === 'reps' && this.props.reps !== null && !_.isEqual(this.props.reps, prevProps.reps)){
            this.removeMarkers();    
            this.drawReps(this.props.reps);
        }
        else if( prevProps.typeOfMarkerToShow !== this.props.typeOfMarkerToShow && this.props.typeOfMarkerToShow === 'leads' || 
            this.props.typeOfMarkerToShow === 'leads' && this.props.leads !== null && !_.isEqual(this.props.leads, prevProps.leads)){
            this.removeMarkers();
            this.drawLeads(this.props.leads);
        }
        else if( prevProps.typeOfMarkerToShow !== this.props.typeOfMarkerToShow && (this.props.typeOfMarkerToShow === 'selected_rep' || this.props.typeOfMarkerToShow === 'selected_rep__area')){
            this.removeMarkers();
            this.drawSelectedBranches(this.props.selectedRep.branches);
        }
        else if( prevProps.typeOfMarkerToShow !== this.props.typeOfMarkerToShow && this.props.typeOfMarkerToShow === 'selected_rep__profile'){
            this.removeMarkers();
            this.drawReps([this.props.selectedRep]);
        }
        else if( prevProps.typeOfMarkerToShow !== this.props.typeOfMarkerToShow && this.props.typeOfMarkerToShow === 'trainers' || 
            this.props.typeOfMarkerToShow === 'trainers' && this.props.trainers !== null && !_.isEqual(this.props.trainers, prevProps.trainers)){
            this.removeMarkers();
            this.drawTrainers(this.props.trainers);
        }
        else if( prevProps.typeOfMarkerToShow !== this.props.typeOfMarkerToShow && this.props.typeOfMarkerToShow === 'selected_trainer' && this.props.selectedTrainer != null || 
            this.props.typeOfMarkerToShow === 'selected_trainer' && this.props.selectedTrainer != null && !_.isEqual(this.props.selectedTrainer, prevProps.selectedTrainer)){
            this.removeMarkers();
            this.drawTrainers([this.props.selectedTrainer]);
        }
    }

    componentDidMount = () => {
        const googleMapScript = document.createElement('script');
        googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&v=3.exp&libraries=geometry,drawing,places`;
        window.document.body.appendChild(googleMapScript);

        const markerClustererScript = document.createElement('script');
        markerClustererScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/markerclustererplus/2.1.4/markerclusterer.min.js';
        window.document.body.appendChild(markerClustererScript);
    
        googleMapScript.addEventListener('load', async () => {
            this.googleMap = this.createGoogleMap();
            this.props.fetchReps();
            this.props.fetchLeads();
            this.props.fetchTrainers();
        });
    }

    createGoogleMap = () => {
        return new window.google.maps.Map(this.googleMapRef.current, 
            {
                zoom: 4,
                center: {
                    lat: 40,
                    lng: -100,
                },
            })
    }

    drawReps = async reps => {
        const markers = [];
        let InfoWindow;
        for(const rep of reps){
            const { branchZip: { latitude: lat, longitude: lng }, BVAgentId } = rep;
            if(!lat || !lng || isNaN(lat) || isNaN(lng)) continue;
            const url = process.env.REACT_APP_ENVIROMENT === 'development' ? './avatar2.png' : `https://www.thebrokernetwork.com/Images/SiteImages/${BVAgentId}/photo_signature.jpg`         
            const icon = {
                anchor: new window.google.maps.Point(0, 0),
                origin: new window.google.maps.Point(0, 0),
                scaledSize: new window.google.maps.Size(32, 32),
                url,
                borderRadius: '50px',
            };
            const marker = new window.google.maps.Marker({
                position: { lat, lng },
                map: this.googleMap,
                icon,
                optimized: false,
            });
            marker.addListener('click', () => {
                if(InfoWindow)
                    InfoWindow.close();
                const content = document.createElement('div');
                ReactDOM.render(
                    <RepInfoWindowContent 
                        rep={rep}
                        setSelectedRep={this.props.setSelectedRep}
                        changeMarkersToShow={this.props.changeMarkersToShow}
                    />,
                    content
                );
                InfoWindow = new window.google.maps.InfoWindow({ content });
                InfoWindow.open(this.googleMap, marker);
            });
            markers.push(marker);
        }
        this.setState({ markers });
    }

    drawLeads = leads => {
        const markers = [];
        for(const lead of leads){
            const { zip: { latitude: lat, longitude: lng }, loanPurpose, isFunded } = lead;
            if(!lat || !lng || isNaN(lat) || isNaN(lng)) continue;
            let icon;
            if (loanPurpose !== null && loanPurpose.substring(0, 3) === 'Pur'){
                icon = isFunded ? './purple-star.png' : './purple-dot.png';
            }
            else if(loanPurpose !== null && loanPurpose.substring(0, 3) === 'Ref') icon = './red-dot.png';
            else console.log(loanPurpose);
            const marker = new window.google.maps.Marker({
                position: { lat, lng },
                map: null,
                icon,
            });
            this.createInfoWindow(marker, 'leads', lead);
            markers.push(marker);
        }
        const markCluster = new window.MarkerClusterer(this.googleMap, markers, 
            { 
                imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
                gridSize: 120,
                maxZoom: 7,
            });
        this.setState({ markers, markCluster });
    }

    drawSelectedBranches = branches => {
        const getConvexHullCoords = coords => {
            const convexHull = new ConvexHullGrahamScan();
        
            coords.forEach(item => {
                convexHull.addPoint(item.lng, item.lat);
            });
        
            return convexHull.getHull().map((item) => {
                return {
                    lat: item.y,
                    lng: item.x
                };
            });
        }

        const findCenter = coords => {

            var x = 0, y = 0, i, len = points.length;
          
            for (i = 0; i < len; i++) {
              x += points[i].lng;
              y += points[i].lat;
            }
            return {x: x / len, y: y / len};   // return average position
        }

        const findAngles = (c, coords)  => {

            var i, len = coords.length, p, dx, dy;
          
            for (i = 0; i < len; i++) {
              p = coords[i];
              dx = p.lng - c.x;
              dy = p.lat - c.y;
              p.angle = Math.atan2(dy, dx);
            }
        }

        // const points = branches.map(branch => {
        //     const latitude    = branch.lat; // (φ)
        //     const longitude   = branch.lng;   // (λ)
            
        //     const mapWidth    = 1437;
        //     const mapHeight   = 400;
            
        //     // get x value
        //     const x = (longitude+180)*(mapWidth/360)
            
        //     // convert from degrees to radians
        //     const latRad = latitude * (Math.PI / 180);
            
        //     // get y value
        //     const mercN = Math.log(Math.tan((Math.PI/4)+(latRad/2)));
        //     const y     = (mapHeight/2)-(mapWidth*mercN/(2*Math.PI));
        //     return { lng: x, lat: y };

        // });

        const points = branches.map(branch => ({ lat: branch.lat, lng: branch.lng }))

        const addAngles = points => findAngles(findCenter(points), points);
        
        // sort for real approach
        addAngles(points);
        
        points.sort(function(a, b) {
            if (a.angle > b.angle) return 1;
            else if (a.angle < b.angle) return -1;
            return 0;
        });

        const p = new window.google.maps.Polygon({
            paths: getConvexHullCoords(points),
            strokeColor: 'black',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: 'black',
            fillOpacity: 0.7
        });
        p.setMap(this.googleMap);
        this.setState({ markers: [p] });
    }

    drawTrainers = trainers => {
        const poligons = [];
        const buttons = [];
        for(const trainer of trainers){
            for(const [stateName, coordinates] of Object.entries(trainer.states)){
                const bounds = new window.google.maps.LatLngBounds();
                for(const value of coordinates){
                    const v = new window.google.maps.LatLng(value.lat, value.lng);
                    bounds.extend(v);
                }
                const centerCoordinates = {
                    lat: bounds.getCenter().lat(),
                    lng: bounds.getCenter().lng(),
                }
                const state = new window.google.maps.Polygon({
                    paths: coordinates,
                    strokeColor: trainer.color,
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: trainer.color,
                    fillOpacity: 0.7
                });
                state.addListener('click', () => {
                    this.props.setSelectedTrainer(trainer);
                    this.props.changeMarkersToShow('selected_trainer');
                });
                this.createInfoWindow(state, 'trainers', { ...trainer, stateName, centerCoordinates });
                state.setMap(this.googleMap);
                poligons.push(state);
            }
            buttons.push(
                <GoogleButton key={trainer.color} containerStyle={{ backgroundColor: trainer.color, color: 'white' }}>
                    {trainer.names.length > 1 ? trainer.names.reduce((acum, value) => acum + ', ' + value) : trainer.names[0]}
                </GoogleButton>
            );
        }
        const position = window.google.maps.ControlPosition.BOTTOM_LEFT;
        const element = document.createElement('div');
        this.googleMap.controls[position].push(element);
        ReactDOM.render(<div>{buttons}</div>, element);
        this.setState({ markers: poligons, customBar: element });

    }

    removeMarkers = () => {
        if(this.state.markCluster != null)
            this.state.markCluster.setMap(null);
        if(this.state.markers != null){
            for(const marker of this.state.markers){
                marker.setMap(null);
            }
        }
        if(this.state.customBar != null){
            ReactDOM.unmountComponentAtNode(this.state.customBar);
        }
    }

    createInfoWindow = (marker, type, element) => {
        if (type === 'leads'){
            marker.addListener('mouseover', () => {
                const { zip, loanPurpose, leadStatusType, isFunded, amount, dateCreated } = element;

                const content = ReactDOMServer.renderToString(<LeadInfoWindowContent
                            zipInfo={zip} 
                            loanPurpose={loanPurpose}
                            leadStatusType={leadStatusType}
                            isFunded={isFunded}
                            amount={amount}
                            dateCreated={dateCreated}
                        />);
                const InfoWindow = new window.google.maps.InfoWindow({ content });
                InfoWindow.open(this.googleMap, marker);
                marker.addListener('mouseout', () => InfoWindow.close());      
            });
     
        }
        else if (type === 'selected_rep'){
            marker.addListener('mouseover', () =>{ 
                const { name } = element;
                const content = ReactDOMServer.renderToString(
                    <BranchInfoWindowContent 
                        name={name}
                    />);
                const InfoWindow = new window.google.maps.InfoWindow({ content });                
                InfoWindow.open(this.googleMap, marker);
                marker.addListener('mouseout', () => InfoWindow.close());
            });
        }
        else if (type === 'trainers'){
            marker.addListener('mouseover', () => { 
                const {
                    names, 
                    stateName,
                    centerCoordinates,
                    emails,
                    phoneNumbers,
                    agentIds,
                } = element;
                const content = ReactDOMServer.renderToString(
                    <TrainerInfoWindow 
                        key={stateName + agentIds[0]}
                        trainerNames={names}
                        stateName={stateName}
                        emails={emails}
                        phoneNumbers={phoneNumbers}
                    />);
                const InfoWindow = new window.google.maps.InfoWindow({ content });
                InfoWindow.setPosition(centerCoordinates)
                InfoWindow.open(this.googleMap, marker);
                marker.addListener('mouseout', () => InfoWindow.close());
            });            
        }
    }
    
    render = () => {
        return(
            <React.Fragment>
                <Col lg={12}
                    ref={this.googleMapRef}
                    style={{
                        width: '100%',
                        height: '600px',
                        margin: '10px',
                        backgroundColor: 'grey'
                    }}
                >
                </Col>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    reps: state.reps.filtered === null ? state.reps.original : state.reps.filtered,
    leads: state.leads.filtered === null ? state.leads.original : state.leads.filtered,
    trainers: state.trainers.filtered === null ? state.trainers.original : state.trainers.filtered,
    selectedRep: state.reps.selected,
    selectedTrainer: state.trainers.selected,
    typeOfMarkerToShow: state.typeOfMarkerToShow,
});

const mapDispatchToProps = dispatch => ({
    setSelectedRep: selectedRep => dispatch(setSelectedRep(selectedRep)),
    fetchReps: () => dispatch(fetchReps()),
    fetchLeads: () => dispatch(fetchLeads()),
    fetchTrainers: () => dispatch(fetchTrainers()),
    setSelectedTrainer: trainer => dispatch(setSelectedTrainer(trainer)),
    changeMarkersToShow: typeOfMarkerToShow => dispatch(changeMarkersToShow(typeOfMarkerToShow)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GoogleMap);
