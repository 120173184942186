import React from 'react';
import Row from 'react-bootstrap/Row';
import moment from 'moment-timezone';

import './LeadInfoWindow.css';

export default class CustomInfoWindow extends React.Component{


    render = () => {
        const { zipInfo, loanPurpose, leadStatusType, isFunded, amount, dateCreated } = this.props;
        const dateFormatted = moment.utc(dateCreated, 'YYYY-MM-DDTHH:mm:ss.SSS').format('MM/DD/YYYY');
        return(
            <div style={ { width: '250px' } }>
                <Row style={ { margin: 0 } }>
                    <span style={ {marginLeft: '10px', fontSize: '15px' } } className='infobox-span'><span style={{fontWeight: 'bold'}}>Zipcode:</span> {zipInfo.zipcode}</span>
                </Row>
                { zipInfo.city &&
                    <Row style={ { margin: '5px 0 0'} }>
                        <span style={ {marginLeft: '10px', fontSize: '15px' } } className='infobox-span'><span style={{fontWeight: 'bold'}}>City:</span> {zipInfo.city}</span>
                    </Row>
                }
                <Row style={ { margin: '5px 0 0'} }>
                    <span style={ {marginLeft: '10px', fontSize: '15px' } } className='infobox-span'><span style={{fontWeight: 'bold'}}>Loan purpose:</span> {loanPurpose}</span>
                </Row>
                <Row style={ { margin: '5px 0 0'} }>  
                    <span style={ {marginLeft: '10px', fontSize: '15px' } } className='infobox-span'><span style={{fontWeight: 'bold'}}>Lead status type:</span> {leadStatusType}</span>
                </Row>
                <Row style={ { margin: '5px 0 0'} }>   
                    <span style={ {marginLeft: '10px', fontSize: '15px' } } className='infobox-span'><span style={{fontWeight: 'bold'}}>Is Funded?:</span> {isFunded ? 'True' : 'False'}</span>
                </Row>
                <Row style={ { margin: '5px 0 0'} }>    
                    <span style={ {marginLeft: '10px', fontSize: '15px' } } className='infobox-span'><span style={{fontWeight: 'bold'}}>Amount of leads:</span> {amount}</span>
                </Row>
                <Row style={ { margin: '5px 0 0'} }>    
                    <span style={ {marginLeft: '10px', fontSize: '15px' } } className='infobox-span'><span style={{fontWeight: 'bold'}}>Date Created:</span> {dateFormatted}</span>
                </Row>
            </div>
        );
    }
}