import React from 'react';
import { createStore, applyMiddleware, compose } from 'redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';

import defaultReducer from './reducers';

import AppWithRouter from './pages/AppWithRouter';

import './styles/custom.scss';

// This is the main component of the App which pulls and organizes all the elements
// it aplies a redux store for handling the state change between components
function App(props){
    const arg = window.__REDUX_DEVTOOLS_EXTENSION__ ? compose(applyMiddleware(thunk), window.__REDUX_DEVTOOLS_EXTENSION__()) : applyMiddleware(thunk);
    
    const store = createStore(defaultReducer, arg);

    return (
        <Provider store={store}>
            <Router>
                <AppWithRouter />
            </Router>
        </Provider>
    );
}

export default App;