import React from 'react';

import UserCard from './UserCard';

export default function (props){
    return(
        <div className="app-header header-shadow">
            <div className="app-header__logo">
                <div className="logo" style={{fontSize: '20px'}}>Local Buyer Connect</div>
            </div>
            <div className="app-header__content">
                <div className="app-header-right">
                    <div className="header-btn-lg pr-0">
                        <div className="widget-content p-0">
                            <div className="widget-content-wrapper">
                                <UserCard />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    );      
}