import React from 'react';

import Navbar from '../../components/Main/DashboardComponent/Navbar';
import MainContainer from '../../components/Main/MainContainer';
import Sidebar from '../../components/Main/Sidebar';

const Dashboard = () => {
    return (
        <div className="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header">
            <Navbar />
            <div className="app-main">
                <Sidebar />
                <MainContainer />
            </div>
        </div>
    );
}

export default Dashboard;