import React from 'react';
import { connect } from 'react-redux';

import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

import { addRepFilter, removeRepFilter }  from '../../../../actions';

class UserStatusComponent extends React.Component {
    
    constructor(props){
        super(props);
        this.state = {
            employmentStatus: undefined,
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if(this.props.reps != null && !this.state.employmentStatus){
            try {
                const employmentStatus = Array.from(new Set(this.props.reps.map(rep => rep.userStatus)))
                                                .filter(us => us != null && us !== 'null' && us !== '');
                this.setState({ employmentStatus: employmentStatus.lenght > 1 ? employmentStatus : null });
            } catch (error) {
                console.error(error);
            }
        }
    }

    mapOptions = options => { 
        return [ 
            <option key={null} value={null} disabled={true} hidden={true} selected={true}>Select branch</option>,
            <option key={0} value={0}>None</option>,
            ...options.map(opt => <option key={opt} value={opt}>{opt}</option>).filter(v => v),
        ];
    }

    onChange = employmentStatus => {
        if(employmentStatus == 0)
            return this.props.removeFilter();
        return this.props.addFilter(reps => this.filter(reps, employmentStatus));
    }

    filter = (reps, employmentStatus) => {
        return reps.filter(e => e.userStatus === employmentStatus);
    }

    render = () => {
        const { formGroupStyle } = this.props;
        const { employmentStatus } = this.state;
        return (
            employmentStatus && 
                <Form.Group
                    as={Col}
                    sm={12}
                    lg={6}
                    controlId="enploymentStatusSelection"
                    onChange={({ target: { value } }) => this.onChange(value)}
                    style={formGroupStyle}
                >
                    <Form.Label>Filter by employment status:</Form.Label>
                    <Form.Control as="select">
                        { this.mapOptions(employmentStatus) }
                    </Form.Control>
                </Form.Group>
            || null
        )
    }
}

const mapStateToProps = state => ({
    reps: state.reps.original,
});

const mapDispatchToProps = dispatch => ({
    addFilter: fn => {
        dispatch(addRepFilter({ name: 'employmentStatus', fn }));
    },
    removeFilter: () => {
        dispatch(removeRepFilter('employmentStatus'));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(UserStatusComponent);