import React from 'react';
import { connect } from 'react-redux';

import { changeMarkersToShow, setSelectedTrainer, removeFilter, applyFilters }  from '../../../../actions';

class RepNameFilter extends React.Component {

    onSuggestionSelected = (event, { suggestion }) => {
        const trainer = this.onFilter(suggestion);
        if(trainer){
            this.props.setSelectedTrainer(trainer);
            this.props.changeMarkersToShow('selected_trainer');
            return;
        }
        this.setState({ errorMessage: 'No trainer for that rep' })
    }

    onFilter = suggestion => {
        for(const trainer of this.props.trainers){
            for(const rep of trainer.reps){
                if(rep.username === suggestion.username)
                    return trainer;
            }
        }
        return false;
    }
}

const mapStateToProps = state => {
    const newState = { reps: state.reps.original, trainers: state.trainers.original };
    const filterKeys = Object.keys(state.trainers.filters);
    if(filterKeys.length > 0)
        newState.reps = state.reps.filtered;
    newState.hasThisFilter = filterKeys.includes('loanOfficerName');
    return newState;
};

const mapDispatchToProps = dispatch => ({
    changeMarkersToShow: typeOfMarker => dispatch(changeMarkersToShow(typeOfMarker)),
    setSelectedTrainer: trainer => dispatch(setSelectedTrainer(trainer)),
    removeAndApplyFilters: () => {
        dispatch(removeFilter('repNameFilter')); 
        dispatch(applyFilters());       
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(RepNameFilter);