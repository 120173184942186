// These are the actions that are invoked via the dispatch, they will trigger an effect
// handled by the reducer

import Operations from '../lib/Operations';

// reps

export const fetchReps = function(filters) {
    return async function(dispatch){
        dispatch({
            type: 'SET_REPS_LOADING',
            payload: { loading: true }
        });
        try {
            const reps = await Operations.UserContactInformation.getAllReps(filters);
            dispatch(setReps(reps));
        } catch (error) {
            console.error(error);
        } finally {
            dispatch({
                type: 'SET_REPS_LOADING',
                payload: { loading: false }
            });
        }
    }
}

export const setReps = function(reps){
    return { type: 'SET_REPS', payload: { reps } };
}

export const setSelectedRep = function(rep){
    return { type: 'SET_SELECTED_REP', payload: { rep } };
}

export const setFilteredReps = function(reps){
    return { type: 'SET_FILTERED_REPS', payload: { reps } };
}

export const addRepFilter = function(filter){;
    return { type: 'ADD_REP_FILTER', payload: { filter } };
}

export const removeRepFilter = function(filter){
    return { type: 'REMOVE_REP_FILTER', payload: { filter } };
}

export const removeAllRepFilters = function() {
    return { type: 'REMOVE_ALL_REP_FILTERS' };
}

// leads

export const fetchLeads = function(filters) {
    return async function(dispatch){
        dispatch({
            type: 'SET_LEADS_LOADING',
            payload: { loading: true }
        });
        try {
            const leads = await Operations.Lead.getPins(filters);
            dispatch(setLeads(leads));
        } catch (error) {
            console.error(error);
        } finally {
            dispatch({
                type: 'SET_LEADS_LOADING',
                payload: { loading: false }
            });
        }
    }
}

export const setLeads = function(leads){
    return { type: 'SET_LEADS', payload: { leads } };
}

export const removeAllLeadFilters = function() {
    return { type: 'REMOVE_ALL_LEAD_FILTERS' };
}

// trainers

export const fetchTrainers = function() {
    return async function(dispatch){
        dispatch({
            type: 'SET_TRAINERS_LOADING',
            payload: { loading: true }
        });
        try {
            const trainers = await Operations.Trainer.getTrainers();
            dispatch(setTrainers(trainers));
        } catch (error) {
            console.error(error);
        } finally {
            dispatch({
                type: 'SET_TRAINERS_LOADING',
                payload: { loading: false }
            });
        }
    }
}

export const setTrainers = function(trainers){
    return { type: 'SET_TRAINERS', payload: { trainers } };
}

export const setSelectedTrainer = function(trainer){
    return { type: 'SET_SELECTED_TRAINER', payload: { trainer } };
}

export const removeAllTrainerFilters = function() {
    return { type: 'REMOVE_ALL_TRAINER_FILTERS' };
}

// MISC

export const changeMarkersToShow = function(typeOfMarkerToShow){
    return { type: 'CHANGE_MARKER', payload: typeOfMarkerToShow }
}

export const setStatusMessage = function(opts){
    return { type: 'SET_MESSAGE', opts };
}

export const addFilter = function(key, fn){
    return { type: 'ADD_FILTER', key, fn }
}

export const removeFilter = function(key){
    return { type: 'REMOVE_FILTER', key }
}

export const applyFilters = function(){
    return { type: 'APPLY_FILTERS' }
}
