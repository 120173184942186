import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Collapse from 'react-bootstrap/Collapse';

import BranchSelectComponent from './RepsFilter/BranchSelectionComponent';
import RegionSelectComponent from './RepsFilter/RegionSelectionComponent';
import LoanOfficerComponent from './RepsFilter/RepNameFilter';
import EmploymentStatusComponent from './RepsFilter/EmploymentStatusComponent';
import RepLoanPurposeComponent from './RepsFilter/LoanPurposeComponent';
import TrainerSelection from './RepsFilter/TrainerSelection';

import LeadLoanPurposeComponent from './LeadsFilter/LoanPurposeComponent';
import ClaimedDateComponent from './ClaimedDateComponent/ClaimedDateComponent';

import RepNameFilter from './TrainerFilter/RepNameFilter';

import ReturnButton from './Generics/ReturnButton';

import { setFilteredReps } from '../../../actions';


class FilteringComponent extends React.Component {

    repFilteringOptions = [
        { type: 'reps', key: 'repBranch', verbose: 'Branch', Component: BranchSelectComponent },
        { type: 'reps', key: 'repRegion', verbose: 'Region', Component: RegionSelectComponent },
        { type: 'reps', key: 'repLoanOfficername', verbose: 'Loan officer name', Component: LoanOfficerComponent },
        { type: 'reps', key: 'repLoanPurpose', verbose: 'Loan purporse', Component: RepLoanPurposeComponent },
        { type: 'reps', key: 'repEmploymentStatus', verbose:'Employment Status', Component: EmploymentStatusComponent },
        // { type: 'reps', key: 'repTrainerSeletion', verbose:'Trainer Selection', Component: TrainerSelection },
    ];

    leadFilteringOptions = [
        { type: 'leads', key: 'leadLoanPurpose', verbose: 'Loan purpose', Component: LeadLoanPurposeComponent },
    ];

    trainerFilteringOptions = [
        // { type: 'trainers', key: 'trainerRepName', verbose: 'Rep name', Component: RepNameFilter },
    ]

    constructor(props){
        super(props);
        this.state = {
            collapseOpen: true,
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if(!_.isEqual(prevProps.reps.filters, this.props.reps.filters)){
            let reps = this.props.reps.original;
            for(const fn of Object.values(this.props.reps.filters)){
                reps = fn(reps);
            }
            this.props.setFilteredReps(reps);
        }
    }

    mapFilteringComponents = ( typeOfMarkerToShow ) => {
        const options = [...this.repFilteringOptions, ...this.leadFilteringOptions, ...this.trainerFilteringOptions,];
        return options.map(option => {
            const { key, Component, type } = option;
            return (<Component
                key={key}
                formGroupStyle={{display: type ===  typeOfMarkerToShow ? 'block' : 'none' }}
            />);
        });
    }

    render = () => {
        const { typeOfMarkerToShow } = this.props;
        const { collapseOpen = true } = this.state;
        return (
            <div>
                <div
                    style={ 
                        {
                            display: 'flex',
                            justifyContent: 'space-between'
                        }
                    }
                    onClick={ () => this.setState({ collapseOpen: !collapseOpen }) }
                >
                    <div style={{ margin: '0 1%', fontSize: '2em', fontWeight: 'bold'}}>
                        <span>Filters</span>
                        <i className={`metismenu-state-icon pe-7s-angle-${collapseOpen ? 'down': 'right'} caret-left`} />
                    </div>
                </div>
                <Collapse in={collapseOpen}>
                    <div>
                        <Row style={{margin: '0 1%'}}>
                            { this.mapFilteringComponents( typeOfMarkerToShow ) }
                        </Row>
                        <Row style={{marginLeft: '1%'}}>
                            <Form.Group 
                                as={Col}
                                sm={12}
                                lg={7}
                                controlId="selectFilterForm"
                            >
                                <ClaimedDateComponent />
                            </Form.Group>
                            {typeOfMarkerToShow && typeOfMarkerToShow === 'leads' &&
                                <Col style={{ marginLeft: '1%'}}>
                                    <li style={{ listStyleType: 'none' }}>
                                        <ul>Key:</ul>
                                        <ul><img src='./purple-dot.png' style={{marginRight: '10px'}}/>Purchase</ul>
                                        <ul><img src='./purple-star.png' style={{marginRight: '10px'}}/>Purchase and Funded</ul>
                                        <ul><img src='./red-dot.png' style={{marginRight: '10px'}}/>Refinance</ul>
                                    </li>
                                </Col>
                            }       
                        </Row>
                    </div>
                </Collapse>
                <ReturnButton />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    typeOfMarkerToShow: state.typeOfMarkerToShow,
    reps: state.reps,
    leads: state.leads,
    trainers: state.trainers,
});

const mapDispatchToProps = dispatch => ({
    setFilteredReps: reps => dispatch(setFilteredReps(reps)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilteringComponent);
