import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Autosuggest from 'react-autosuggest';

import { addRepFilter, removeRepFilter }  from '../../../../actions';

import './LoanOfficerComponent.css';

export class RepNameFilter extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            value: '',
            errorMessage: false,
        }
    }

    // componentDidUpdate = (prevProps, prevState) => {
    //     if((this.props.reps !== null || this.props.reps.length === 0) && !_.isEqual(prevProps.reps, this.props.reps)){
    //         if(this.props.hasThisFilter) this.props.removeAndApplyFilters();
    //         return this.setState({ loanOfficers: [] });
    //     }
    //     const loanOfficers = this.props.reps.map(rep => ({ username: rep.username, name: rep.firstname + ' ' + rep.lastname }));
    //     this.setState({ loanOfficers });
    // }

    onSuggestionSelected = (event, { suggestion }) => {
        return this.props.addFilter(reps => this.filter(reps, suggestion));
    }

    onSuggestionsClearRequested = () => {
        const { value } = this.state;
        if(value == null || value == '')
            return this.props.removeFilter();
    }

    filter = (reps, suggestion) => {
        const foundElem = reps.find(rep => rep.username === suggestion.username);
        if (foundElem) return [foundElem];
        return [];
    }

    getSuggestions = value => {
        const escapedValue = value.trim().replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
        if (escapedValue === '') {
            return [];
        }
        const regex = new RegExp(escapedValue, 'i');
        return this.props.reps.filter(loanOfficer => {
            const name = loanOfficer.firstname + ' ' + loanOfficer.lastname;
            return regex.test(name);
        });
    }

    getRenderInputComponent = inputProps => {
        const { className: originalClassName } = inputProps;
        const className = `${originalClassName} form-control`;
        return(
            <div>
                <input 
                { ...inputProps }
                className={className}
                />
            </div>
        )
    }

    render = () => {
        const { value, errorMessage } = this.state;
        const { formGroupStyle } = this.props;
        return (
            this.props.reps !== null && 
            <Form.Group
                as={Col}
                sm={12}
                lg={6}
                style={formGroupStyle}
                controlId="loanOfficerForm"
            >
                <Form.Label>Loan officer name:</Form.Label>
                <Autosuggest
                    suggestions={this.getSuggestions(value)}
                    inputProps={{
                        placeholder: 'Type the name of the loan officer',
                        value,
                        onChange: (event, { newValue } ) => this.setState({ value: newValue })
                    }}
                    renderInputComponent={this.getRenderInputComponent}
                    onSuggestionsFetchRequested={({value, reason}) => this.setState({ errorMessage: null })}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                    onSuggestionSelected={this.onSuggestionSelected}
                    getSuggestionValue={value => value.firstname + ' ' + value.lastname}
                    renderSuggestion={value => <span>{value.firstname + ' ' + value.lastname}</span>}
                    focusInputOnSuggestionClick={false}
                />
                { errorMessage && <span style={{ color: 'red' }}>{errorMessage}</span> }
            </Form.Group> ||
            null
        )
    }
}

const mapStateToProps = state => ({
    reps: state.reps.filtered != null && state.reps.filtered.length > 0 ? state.reps.filtered : state.reps.original,
});

const mapDispatchToProps = dispatch => ({
    addFilter: fn => {
        dispatch(addRepFilter({ name: 'repName', fn }));
    },
    removeFilter: () => {
        dispatch(removeRepFilter('repName'));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(RepNameFilter);