const trainers = {
    original: null,
    filtered: null,
    filters: [],
    selected: null,
    loading: false,
}

export default function(state = trainers, action){
    switch(action.type){
        case 'SET_TRAINERS_LOADING': {
            return { ...state, loading: action.payload.loading };
        }
        case 'SET_TRAINERS': {
            return { ...state, original: action.payload.trainers };
        }
        case 'SET_SELECTED_TRAINER': {
            return { ...state, selected: action.payload.trainer };
        }
        default:
            return state;
    }
}