import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Row from 'react-bootstrap/Row';

import RepProfile from './RepProfile/RepProfile';
import TrainerProfile from './TrainerProfile/TrainerProfile';

const ProfileSelector = props => {
    let element = null
    if((props.typeOfMarkerToShow === 'selected_rep' || props.typeOfMarkerToShow === 'selected_rep__profile') && props.selectedRep && props.trainers){
        element = <RepProfile trainers={props.trainers} rep={props.selectedRep} />
    }
    else if(props.typeOfMarkerToShow === 'selected_trainer' && props.selectedTrainer){
        const trainers = [];
        for(let i = 0; i < props.selectedTrainer.names.length; i++){
            const trainer = { ...props.selectedTrainer };
            trainer.agentId = props.selectedTrainer.agentIds[i];
            trainer.name = props.selectedTrainer.names[i];
            trainer.email = props.selectedTrainer.emails[i];
            trainer.phoneNumber = props.selectedTrainer.phoneNumbers[i];
            trainers.push(<TrainerProfile key={trainer.agentId} trainer={trainer} reps={props.reps} />);
        }
        element = <Row>{trainers}</Row>;
    }
    return(
        <div style={{ marginBottom: '100px'}} >
            { element }
        </div>
    )
}

const mapStateToProps = state => ({
    typeOfMarkerToShow: state.typeOfMarkerToShow,
    selectedRep: state.reps.selected,
    reps: state.reps.original,
    trainers: state.trainers.original,
    selectedTrainer: state.trainers.selected,
});


export default connect(mapStateToProps, null)(ProfileSelector);