import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

import { addRepFilter, removeRepFilter }  from '../../../../actions';

class RegionSelectionComponent extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            regions: undefined,
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if(this.props.reps != null && !this.state.regions){
            const regionObj = {};
            for (const rep of this.props.reps){
                const { region } = rep;
                if(region == null) continue;
                if(!regionObj[region]){
                    regionObj[region] = [rep.username];
                } else {
                    regionObj[region].push(rep.username);
                }
            }
            this.setState({ regions: regionObj });
        }
    }

    onChange = selectedRegion => {
        if(selectedRegion == 0){
            this.props.removeFilter();
            return;
        }
        this.props.addFilter((reps) => this.filter(reps, this.state.regions, selectedRegion));
    }

    filter = (reps, regions, selectedRegion) => {
        const repsMapped = [];
        for(const username of regions[selectedRegion]){
            const foundElem = reps.find(e => e.username === username);
            if (foundElem) repsMapped.push(foundElem);
        }
        return repsMapped;
    }

    mapOptions = options => {
        const optionsMapped = [ 
            <option key={null} value={null} disabled={true} hidden={true} selected={true}>Select region</option>,
            <option key={0} value={0}>None</option>
        ];
        for(const key in options){
            if(options[key].length === 0) continue;
            optionsMapped.push(<option key={key} value={key}>{key}</option>);
        }
        return optionsMapped;
    }

    render = () => {
        const { regions } = this.state;
        const { formGroupStyle } = this.props;
        return (
            regions &&
                <Form.Group
                    as={Col}
                    sm={12}
                    lg={6}
                    style={formGroupStyle}
                    controlId="regionSelection"
                    onChange={(e) => this.onChange(e.target?.value)}
                >
                <Form.Label>Filter by region:</Form.Label>
                <Form.Control as="select">
                    { this.mapOptions(regions) }
                </Form.Control>
            </Form.Group>
            || null
        );
    }
}

const mapStateToProps = state => ({
    reps: state.reps.filtered != null ? state.reps.filtered : state.reps.original,
});

const mapDispatchToProps = dispatch => ({
    addFilter: fn => {
        dispatch(addRepFilter({ name: 'regionSelection', fn }));
    },
    removeFilter: () => {
        dispatch(removeRepFilter('regionSelection'));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(RegionSelectionComponent);
