import _ from 'lodash';

import React from 'react';
import { connect } from 'react-redux';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate } from 'react-day-picker/moment';
import moment from 'moment-timezone';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';

import { setReps, setLeads, applyFilters }  from '../../../../actions';

import Operations from '../../../../lib/Operations';

import 'react-day-picker/lib/style.css'

class ClaimedDateComponent extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            leadDate: { initial: moment().subtract('30', 'days'), end: moment(), clicked: true },
            filterMessage: null,
            errorMessage: null,
        }
    }

    componentDidMount = () => {
        this.setState({ filterMessage: this.getNowFiltering() });
    }

    onChangeDateBtnClick = async () => {
        const { typeOfMarkerToShow } = this.props;
        try {
            this.setState({ errorMessage: null });
            if(typeOfMarkerToShow === 'leads'){
                const { leadDate } = this.state;
                if(leadDate.initial == null || leadDate.end == null) throw Error('Date not filled');
                if(leadDate.end < leadDate.initial) throw Error('Date range error');
                const leads = await Operations.Lead.getPins({
                    dateCreatedInitial: moment(leadDate.initial).format('YYYY-MM-DD'), 
                    dateCreatedEnd:  moment(leadDate.end).format('YYYY-MM-DD')
                });
                this.props.setLeads(leads);
                this.setState({ leadDate }, () => {
                    this.setState({ filterMessage: this.getNowFiltering() });
                });
            }
        } catch (error) {
            console.error(error);
            this.setState({ errorMessage: error.toString() });
        }
    }
    
    getNowFiltering = () => {
        const { leadDate } = this.state;
        const { initial, end } = leadDate;
        const message = `leads from the last ${moment(end).diff(moment(initial), 'days')} days`;
        return(
            <label style={{marginLeft: '10px', color: 'green'}}>
                Now filtering {message}
                <span style={{marginLeft: '10px', color: 'red'}}>From: {moment(initial).format('MM/DD/YYYY')}</span>
                <span style={{marginLeft: '10px', color: 'red'}}>To: {moment(end).format('MM/DD/YYYY')}</span>
            </label>
        );
    }

    getDisabledDays = () => {
        const { leadDate: { initial } } = this.state;
        return initial != null ? { before: moment(initial).toDate() } : null;
    }


    setNewDate = (option, date, cb) => {
        const { typeOfMarkerToShow } = this.props;
        if(typeOfMarkerToShow === 'leads'){
            const { leadDate } = this.state;
            leadDate[option] = date;
            return this.setState({ leadDate, clicked: false}, cb);
        }
    }

    getValue = () => {
        const { typeOfMarkerToShow } = this.props;
        if(typeOfMarkerToShow === 'leads'){
            const { initial, end } = this.state.leadDate;
            return { initial: moment(initial).toDate(), end: moment(end).toDate() };
        }
    }

    resetToDefault = () => {
        const initial = moment().subtract('30', 'days');
        const end = moment();
        this.setNewDate('initial', initial, () => { 
            this.setNewDate('end', end, () => { this.onChangeDateBtnClick() });
        });
    }

    render = () => {
        const { errorMessage, filterMessage } = this.state;
        if(!this.props.shouldShow) return null;
        return (
            <Form>
                <Form.Label>Filter leads by date range:</Form.Label>
                { filterMessage }
                <Row style={{ justifyContent: 'flex-end' }}>
                    <Form.Group 
                        as={Col}
                        sm={12}
                        lg={6}
                        controlId="beforeDateSelection"
                    >
                        { <span style={{marginRight: '10px'}}>From:</span> }
                        <DayPickerInput
                            inputProps={{className: 'form-control'}}
                            formatDate={formatDate}
                            style={{ display: 'block'}}
                            onDayChange={newDate => this.setNewDate('initial', newDate)}
                            value={this.getValue().initial}
                        />
                    </Form.Group>
                    <Form.Group
                        as={Col}
                        sm={12}
                        lg={6}
                    >
                        { <span style={{marginRight: '10px'}}>To:</span> }
                        <DayPickerInput
                            inputProps={{className: 'form-control'}}
                            formatDate={formatDate}
                            style={{ display: 'block'}}
                            value={this.getValue().end}
                            dayPickerProps={{ disabledDays : this.getDisabledDays() }}
                            onDayChange={newDate => this.setNewDate('end', newDate)}
                        />
                    </Form.Group>
                    <div style={{ marginRight: '15px'}}>
                        <Button variant='info' style={{ marginRight: '15px' }} onClick={this.resetToDefault}>Reset to default</Button>
                        <Button variant='info' onClick={this.onChangeDateBtnClick}>Change Date</Button>
                    </div>
                    { errorMessage && <span style={{color: 'red'}}>{ errorMessage }</span>}
                </Row>
            </Form>
        );
    }
}

const mapDispatchToProps = dispatch => ({ 
    setReps: reps => { 
        dispatch(setReps(reps));
        dispatch(applyFilters(reps));
    },
    setLeads: leads => { 
        dispatch(setLeads(leads));
        dispatch(applyFilters(leads));
    }
});

const mapStateToProps = state => ({
    typeOfMarkerToShow: state.typeOfMarkerToShow,
    shouldShow: state.typeOfMarkerToShow === 'leads',
});

export default connect(mapStateToProps, mapDispatchToProps)(ClaimedDateComponent);