import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

import Operations from '../../../../lib/Operations';

import { setReps, applyFilters }  from '../../../../actions';

class LoanPurposeComponent extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            purposes: undefined,
            reps: undefined,
        }
    }

    componentDidUpdate = async (prevProps, prevState) => {
        try {
            if(this.props.reps != null && !_.isEqual(prevProps.reps, this.props.reps)){
                if(this.state.purposes == null){
                    const purposes = await Operations.ReportData.getLoanPurposeFilters();
                    this.setState({ purposes, reps: this.props.reps });
                } else {
                    this.setState({ reps: this.props.reps });
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    mapOptions = (options) => { 
        return [ 
            <option key={null} value={null}>None</option>,
            ...options.map(element => {
                if(element === null || element === 'null' || element === '' || element.length === 0) return;
                return <option key={element} value={element}>{element}</option>
            }).filter(v => v),
        ];
    }

    onFilter = async ( loanPurpose ) => {
        const { reps: originalReps } = this.state;
        try {
            const reps = await Operations.UserContactInformation.getAllReps({ loanPurpose });
            if (!reps || reps.length === 0) throw Error('No new reps');
            this.props.setRepsAndApplyFilter(reps);
        } catch (error) {
            console.error('There was an error ', error);
            return originalReps;
        }
    }


    render = () => {
        const { formGroupStyle } = this.props;
        const { purposes } = this.state;
        return (
            purposes &&
                <Form.Group
                    as={Col}
                    sm={12}
                    lg={6}
                    style={formGroupStyle}
                    controlId="loanPurposeForm"
                    onChange={({target: { value }}) => this.onFilter(value)}
                >
                    <Form.Label>Filter by loan purpose:</Form.Label>
                    <Form.Control as="select">
                        { this.mapOptions(purposes) }
                    </Form.Control>
                </Form.Group>
            || null
        )
    }
}

const mapStateToProps = state => ({
    reps: state.reps.original,
});

const mapDispatchToProps = ( dispatch ) => ({ 
    setRepsAndApplyFilter: (reps) => {
        dispatch(setReps(reps));
        dispatch(applyFilters());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(LoanPurposeComponent);