import React from 'react';
import { connect } from 'react-redux';

import Button from 'react-bootstrap/Button';

import { changeMarkersToShow } from '../../actions';

const Sidebar = props => {
    return(
        <div className="app-sidebar sidebar-shadow">
            <div className="app-header__logo">
                <div className="logo-src"></div>
                <div className="header__pane ml-auto">
                    <div>
                        <button type="button" className="hamburger close-sidebar-btn hamburger--elastic" data-class="closed-sidebar">
                            <span className="hamburger-box">
                                <span className="hamburger-inner"></span>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            <div className="app-header__mobile-menu">
                <div>
                    <button type="button" className="hamburger hamburger--elastic mobile-toggle-nav">
                        <span className="hamburger-box">
                            <span className="hamburger-inner"></span>
                        </span>
                    </button>
                </div>
            </div>
            <div className="app-header__menu">
                <span>
                    <button type="button" className="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav">
                        <span className="btn-icon-wrapper">
                            <i className="fa fa-ellipsis-v fa-w-6"></i>
                        </span>
                    </button>
                </span>
            </div>    
            <div className="scrollbar-sidebar">
                <div className="app-sidebar__inner">
                    <ul className="vertical-nav-menu">
                        <li className="app-sidebar__heading">Marker to show</li>
                        <li className="type-of-marker">
                            <Button
                                className={ props.typeOfMarkerToShow.includes('rep') ? 'mm-active' : 'mm' }
                                onClick={() => props.changeMarkersToShow('reps')}
                                variant='link'
                            >
                                <i className="metismenu-icon fas fa-map-marker-alt"></i>
                                Reps
                            </Button>
                            { props.theresLeads &&
                                <Button
                                    className={ props.typeOfMarkerToShow === 'leads' ?  'mm-active' : 'mm' }
                                    onClick={ () => props.changeMarkersToShow('leads') }
                                    variant='link'
                                >
                                    <i className="metismenu-icon fas fa-map-marker-alt"></i>
                                    Leads
                                </Button>
                            }
                            { props.theresTrainers &&
                                <Button
                                    className={ props.typeOfMarkerToShow.includes('trainer') ? 'mm-active' : 'mm' }
                                    onClick={ () => props.changeMarkersToShow('trainers') }
                                    variant='link'
                                >
                                    <i className="metismenu-icon fas fa-map-marker-alt"></i>
                                    Trainers
                                </Button>
                            }
                        </li>
                    </ul>
                </div>
            </div>
        </div>   
    );
}

const mapStateToProps = state => ({
    theresLeads: state.leads.original != null,
    theresReps: state.reps.original != null,
    theresTrainers: state.reps.original != null && state.trainers.original != null,
    typeOfMarkerToShow: state.typeOfMarkerToShow,
});

const mapDispatchToProps = dispatch => ({
    changeMarkersToShow: (typeOfMarkerToShow) => dispatch(changeMarkersToShow(typeOfMarkerToShow))
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);