import ES6Promise from 'es6-promise';
import axios from 'axios';
ES6Promise.polyfill();

// UserContactInformation handles de initial information of the user
const UserContactInformation = { 
    getAllReps: async function({ loanPurpose, claimedDateInitial, claimedDateEnd } = {}){
        try {
            const { data } = await axios({
                url: `/api/uci/all`,
                method: 'GET',
                params: { loanPurpose, claimedDateInitial, claimedDateEnd },
                headers: {
                    'Access-Control-Allow-Origin': '*'
                }
            });
            return data;
        } catch (error) {
            throw error;
        }
    }
}

const BranchUsername = {
    getBranchesAndUsernames: async function(){
        try {
            const { data } = await axios({
                url: `/api/bu/all`,
                method: 'GET',
                headers: {
                    'Access-Control-Allow-Origin': '*'
                }
            });
            return data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    },

    getAmountOfBranches: async function(){
        try {
            const { data } = await axios({
                url: `/api/bu/amount`,
                method: 'GET',
                headers: {
                    'Access-Control-Allow-Origin': '*'
                }
            });
            return data.amount;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
}

const BranchZipcode = {
    getZipcodesForUser: async function(username){

        try {
            const { data } = await axios({
                url: `/api/bz/zipcodes/${username}`,
                method: 'GET',
                headers: {
                    'Access-Control-Allow-Origin': '*'
                }
            });
            return data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    },
}

const Branch = {
    getAllRegions: async function(){
        try {
            const { data } = await axios({
                url: `/api/b/regions`,
                method: 'GET',
                headers: {
                    'Access-Control-Allow-Origin': '*'
                }
            });
            return data;
        } catch (error) {
            throw error;
        }
    }
}

const Lead = {
    getPins: async function({ dateCreatedInitial, dateCreatedEnd } = {}){
        try {
            const { data } = await axios({
                url: `/api/l/all/pins`,
                method: 'GET',
                headers: {
                    'Access-Control-Allow-Origin': '*'
                },
                params: { dateCreatedInitial, dateCreatedEnd },
            });
            return data;
        } catch (error) {
            throw error;
        }
    }
}

const Trainer = {
    getTrainers: async () => {
        try {
            const { data } = await axios({
                url: `/api/t/state/trainers`,
                method: 'GET',
                headers: {
                    'Access-Control-Allow-Origin': '*'
                }
            });
            return data;
        } catch (error) {
            throw error;
        }
    }
}

const ReportData = {

    getLoanPurposeFilters: async function(){
        try {
            const { data } = await axios({
                url: `/api/rd/filters`,
                method: 'GET',
                headers: {
                    'Access-Control-Allow-Origin': '*'
                }
            });
            return data;
        } catch (error) {
            throw error;
        }
    },
}

const CustomOperations = {
    getUsersFromBranch: function(users, usernames){
        const results = [];
        for(const username of usernames){
            const user = users.find(element => element.username === username);
            results.push(user);
        }
        return results;
    }
}

const PageSettings = {
    getSettings: async function(){
        try {
            const { data } = await axios({
                url: `/api/s/last-updated`,
                method: 'GET',
                headers: {
                    'Access-Control-Allow-Origin': '*'
                }
            });
            return data;
        } catch (error) {
            throw error;
        }
    }
}

export default { 
    UserContactInformation,
    BranchUsername,
    CustomOperations,
    Branch,
    Lead,
    Trainer,
    ReportData,
    BranchZipcode,
    PageSettings,
}