import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Autosuggest from 'react-autosuggest';

import { addRepFilter, removeRepFilter }  from '../../../../actions';

import './LoanOfficerComponent.css';

export class TrainerSelection extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            trainers: undefined,
            value: '',
            errorMessage: false,
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if(_.isEqual(prevProps.trainers, this.props.trainers)) return;
        if(this.props.trainers == null || this.props.trainers.length === 0){
            if(this.props.hasThisFilter) this.props.removeAndApplyFilters();
            return this.setState({ trainers: [] });
        }
        let names = [];
        for(const trainer of this.props.trainers){
            names = [ ...names, ...trainer.names];
        }
        this.setState({ trainers: Array.from(new Set(names)) });
    }

    onSuggestionSelected = (event, { suggestion }) => {
        return this.props.addAndApplyFilters(reps => this.onFilter(suggestion, reps));
    }

    onSuggestionsClearRequested = () => {
        const { value } = this.state;
        if(value == null || value == '')
            return this.props.removeAndApplyFilters();
    }

    onFilter = (suggestion, reps) => {
        const repsFiltered = [];
        for(const rep of reps){
            if(rep.trainer?.name?.includes(suggestion))
                repsFiltered.push(rep);
        }
        return repsFiltered;
    }

    getSuggestions = value => {
        const { trainers } = this.state;
        const escapedValue = value.trim().replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
        if (escapedValue === '') {
            return [];
        }
        const regex = new RegExp(escapedValue, 'i');
        return trainers.filter(trainer => regex.test(trainer));
    }

    getRenderInputComponent = inputProps => {
        const { className: originalClassName } = inputProps;
        const className = `${originalClassName} form-control`;
        return(
            <div>
                <input 
                { ...inputProps }
                className={className}
                />
            </div>
        )
    }

    render = () => {
        const { trainers, value, errorMessage } = this.state;
        const { formGroupStyle } = this.props;
        return (
            trainers && 
            <Form.Group
                as={Col}
                sm={12}
                lg={6}
                style={formGroupStyle}
                controlId="trainersSelection"
            >
                <Form.Label>Trainer name:</Form.Label>
                <Autosuggest
                    suggestions={this.getSuggestions(value)}
                    inputProps={{
                        placeholder: 'Type the name of the trainer',
                        value,
                        onChange: (event, { newValue } ) => this.setState({ value: newValue })
                    }}
                    renderInputComponent={this.getRenderInputComponent}
                    onSuggestionsFetchRequested={({value, reason}) => this.setState({ errorMessage: null })}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                    onSuggestionSelected={this.onSuggestionSelected}
                    getSuggestionValue={value => value}
                    renderSuggestion={value => <span>{value}</span>}
                    focusInputOnSuggestionClick={false}
                />
                { errorMessage && <span style={{ color: 'red' }}>{errorMessage}</span> }
            </Form.Group> ||
            null
        )
    }
}

const mapStateToProps = state => ({
    trainers: state.trainers.original,
});

const mapDispatchToProps = dispatch => ({ 

});

export default connect(mapStateToProps, mapDispatchToProps)(TrainerSelection);