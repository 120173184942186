import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

import { addFilter, removeFilter, applyFilters }  from '../../../../actions';

const LoanPurposeComponent = props => {

    const [ selectedPurpose, setSelectedPurpose ] = React.useState('Both');
    const { formGroupStyle } = props;
    
    const onChange = selectedPurpose => {
        setSelectedPurpose(selectedPurpose);
        if(selectedPurpose === 'Both')
            return props.removeAndApplyFilters();
        return props.addAndApplyFilters(leads => onFilter(selectedPurpose, leads));
    };

    const onFilter = (selectedPurpose, leads) => {
        console.log(selectedPurpose, leads);
        return leads.filter( lead => lead.loanPurpose && lead.loanPurpose.substring(0, 3) === selectedPurpose );
    };

    return (
        <Form.Group
            as={Col}
            controlId="leadLoanPurpose"
            style={formGroupStyle}
            onChange={ ({ target: { value } }) => onChange(value) }
        >
            <Form.Label>Select the loan purpose status:</Form.Label>
            <Form.Check label="Purchase" type='radio' style={{margin: '10px 0'}} id='radio-1' value={'Pur'} checked={selectedPurpose === 'Pur'} />
            <Form.Check label="Refinace" type='radio' style={{margin: '10px 0'}} id='radio-2' value={'Ref'} checked={selectedPurpose === 'Ref'}/>
            <Form.Check label="Both" type='radio' style={{margin: '10px 0'}} id='radio-3' value={'Both'} checked={selectedPurpose === 'Both'}/>
        </Form.Group>
    );
};

const mapDispatchToProps = dispatch => ({ 
    addAndApplyFilters: fn => { 
        dispatch(addFilter('leadLoanPurpose', fn)); 
        dispatch(applyFilters());
    },
    removeAndApplyFilters: () => {
        dispatch(removeFilter('leadLoanPurpose')); 
        dispatch(applyFilters());
    }
});

export default connect(null, mapDispatchToProps)(LoanPurposeComponent);
