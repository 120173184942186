import React, { useState, useEffect } from 'react';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Row from 'react-bootstrap/Row';
import { useOktaAuth } from '@okta/okta-react';

import moment from 'moment-timezone';

import Operations from 'lib/Operations';

import './UserCard.scoped.scss';

export default function UserCard(props){
    const { oktaAuth } = useOktaAuth();
    const [ user, setUser ] = useState(null);
    const [ pageSettings, setPageSettings ] = useState(null);

    useEffect(() => {
        if (!oktaAuth.isAuthenticated) {
        } else {
            oktaAuth.getUser().then((info) => {
                setUser(info);
            });
            Operations.PageSettings.getSettings()
                .then(settings => setPageSettings(moment.tz(settings.lastUpdated, 'Etc/UTC').tz('America/Los_Angeles').format('[Last Updated at] MM/DD/YYYY hh:mm A')))
                .catch(console.error);
        }
    }, [oktaAuth]);

    const onLogoutButtonClick = () => {
        oktaAuth.signOut();
    }
    return (
        <React.Fragment>
            { user &&
                <div className="widget-content-left ml-3 header-user-info">
                    <Row>
                        <div className='name-container'>
                            <div className="widget-heading">
                                Welcome Back,
                            </div>
                            <div className="widget-subheading">
                                { user.name }
                            </div>
                        </div>
                        <NavDropdown 
                            id="basic-nav-dropdown"
                            title=""
                        >
                            <NavDropdown.Item disabled={true}>Settings</NavDropdown.Item>
                            <NavDropdown.Divider />
                            {
                                pageSettings != null && (
                                    <>
                                        <NavDropdown.Item disabled={true}>{pageSettings}</NavDropdown.Item>
                                        <NavDropdown.Divider />
                                    </>
                                )
                            }
                            <NavDropdown.Item onClick={onLogoutButtonClick}>Logout</NavDropdown.Item>
                        </NavDropdown>
                    </Row>
                </div>
            }
        </React.Fragment>
    );
}