import React from 'react';

export default function Button(props){
    const { children, buttonProps = {}, containerStyle = {} } = props;
    return(
        <div role='button' {...buttonProps}
            style={{
                direction: 'ltr',
                overflow: 'hidden',
                textAlign: 'center',
                height: '40px',
                display: 'table-cell',
                verticalAlign: 'middle', 
                position: 'relative',
                color: 'rgb(0, 0, 0)',
                fontFamily: 'Roboto, Arial, sans-serif',
                userSelect: 'none',
                fontSize: '18px',
                backgroundColor: 'rgb(255, 255, 255)',
                padding: '0px 17px',
                borderBottomLeftRadius: '2px',
                borderTopleftRadius: '2px',
                backgroundClip: 'padding-box',
                boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px',
                minWidth: '36px',
                fontWeight: '500',
                ...containerStyle,
            }}
        >
            { children }
        </div>
    );
}