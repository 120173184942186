import React from 'react';
import Row from 'react-bootstrap/Row';

export default class CustomInfoWindow extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            name: props.name,
        }
    }

    render = () => {
        const { name } = this.state;
        return(
            <div style={ { width: '250px' } }>
                <Row style={ { margin: 0 } }>
                    <span style={ {marginLeft: '10px' } } className='infobox-span'>{name}</span>
                </Row>
            </div>
        );
    }
}