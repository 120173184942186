import { combineReducers } from 'redux';

import reps from './reps';
import leads from './leads';
import trainers from './trainers';
import typeOfMarkerToShow from './typeOfMarkerToShow';

export default combineReducers({
    reps,
    leads,
    trainers,
    typeOfMarkerToShow
});
