import React, { useState } from 'react';

import { connect } from 'react-redux';

import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";
import Collapse from 'react-bootstrap/Collapse';
import Button from 'react-bootstrap/Button';

import { changeMarkersToShow, setSelectedRep } from '../../../../actions';
import Operations from '../../../../lib/Operations';

import ProfileCard from '../../DashboardComponent/ProfileCard';

import './TrainerProfile.scoped.scss';

const TrainerProfile = props => {
    const { trainer, reps } = props;
    const [ collapseStates, setCollapseStates ] = useState(false);
    const [ collapseReps, setCollapseReps ] = useState(false);

    const getStates = () => {
        return( 
            <div>
                <ul>
                    {
                        Object.keys(trainer.states).map(state => {
                            return(
                                <li key={state}>
                                    <span>{state}</span>
                                </li>
                            );
                        })
                    }
                </ul>
            </div>
        );
    }

    const handleRepClick = (username) => {
        setRep(reps.find(v => v.username === username));
    }

    const getReps = () => {
        const repsFiltered = reps.filter(rep => trainer.repIds.includes(rep.BVAgentId));
        return (
            <div>
                <ul>
                    {repsFiltered.map(rep => {
                        return(
                            <li key={rep.BVAgentId}>
                                <Button
                                    variant="link"
                                    onClick={() => handleRepClick(rep.username)}
                                    className='replink'
                                >
                                    {rep.firstname + ' ' + rep.lastname}
                                </Button>
                            </li>
                        )
                    })}
                </ul>
            </div>
        );
    };

    const setRep = async rep => {
        const elements = [];
        const results = await Operations.BranchZipcode.getZipcodesForUser(rep.username);
        for(const { zipcode, latitude: lat, longitude: lng, branchName } of results){
            if(lat == null || lng == null || isNaN(lat) || isNaN(lng)) continue;
            elements.push(
                {
                    id: zipcode,
                    name: branchName + ' ' + zipcode,
                    lat, 
                    lng,
                });
        }
        props.setSelectedRep({ ...rep, branches: elements });
        props.changeMarkersToShow('selected_rep');
    };

    const getRegionText = () => {
        const arr = [];
        for(let i = 0; i < trainer.regionIds.length; i++){
            const badge = (<div
                            key={trainer.regionIds[i]}
                            className='badge badge-primary'
                            style={{ textTransform: 'none' }}
                            >
                                { trainer.regionIds[i] + ' ' + trainer.regionNames[i] }
                            </div>);
            arr.push(badge);
        }
        return arr;
    }

    return(
        <Col lg={6} style={{ marginBottom: '20px' }}>
            <ProfileCard style={{ flexDirection: 'column' }}>
                <Col style={{ padding: '2%' }}>
                    <Row>
                        <Col lg={12}>
                            <div className='trainerHeader'>
                                <span>{ trainer.name }</span>
                                <a href={"mailto:" + trainer.email }>({trainer.email})</a>
                            </div>
                            <div className='badges'>
                                { getRegionText() }
                            </div>
                            <div>
                                <span>Agent ID: {trainer.agentId}</span>
                            </div>
                            <div>
                                <span>Telephone: {trainer.phoneNumber}</span>
                            </div>
                            <div>
                                <div onClick={ () => setCollapseStates(!collapseStates)}>
                                    <span>States: ({Object.keys(trainer.states).length})</span>
                                    <i className={`metismenu-state-icon pe-7s-angle-${collapseStates ? 'down': 'right'} caret-left`} />
                                </div>
                                <Collapse in={collapseStates}>
                                    { getStates() }
                                </Collapse>
                            </div>
                            <div>
                                <div onClick={ () => setCollapseReps(!collapseReps)}>
                                    <span>Reps: ({ trainer.repIds.length })</span>
                                    <i className={`metismenu-state-icon pe-7s-angle-${collapseReps ? 'down': 'right'} caret-left`} />
                                </div>
                                <Collapse in={collapseReps}>
                                    { getReps() }
                                </Collapse>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </ProfileCard>
        </Col>
    );
}

const mapDispatchToProps = dispatch => ({
    changeMarkersToShow: typeOfMarker => dispatch(changeMarkersToShow(typeOfMarker)),
    setSelectedRep: rep => dispatch(setSelectedRep(rep)),
});

export default connect(null, mapDispatchToProps)(TrainerProfile);