import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import Operations from '../../../../lib/Operations';

const RepInfoWindow = props => {
    const name = props.rep.firstname + ' ' + props.rep.lastname;
    const onBtnClick = async typeOfMarker => {
        try {
            const { rep, setSelectedRep, changeMarkersToShow } = props;
            const elements = [];
            const results = await Operations.BranchZipcode.getZipcodesForUser(rep.username);
            for(const { zipcode, latitude: lat, longitude: lng, branchName } of results){
                if(lat == null || lng == null || isNaN(lat) || isNaN(lng)) continue;
                elements.push(
                    {
                        id: zipcode,
                        name: branchName + ' ' + zipcode,
                        lat, 
                        lng,
                    });
            }
            setSelectedRep({ ...rep, branches: elements });
            changeMarkersToShow(typeOfMarker);
        } catch(e) {
            console.error(e);
            throw e;
        }
    }
    return(
        <Col>
            <Row style={{ marginBottom: '10px' }}>
                <Col>
                    <span style={{fontWeight: 'bold' }} className='infobox-span'>{name}</span>
                </Col>
            </Row>
            <Row>
                <Button style={{ marginLeft: '10px'}} onClick={() => onBtnClick('selected_rep__area')}>Show area covered</Button>
                <Button style={{ marginLeft: '10px'}} onClick={() => onBtnClick('selected_rep__profile')}>Show rep info</Button>
                <Button style={{ marginLeft: '10px'}} onClick={() => onBtnClick('selected_rep')}>Show both</Button>
            </Row>
        </Col>
    );
}

export default RepInfoWindow;