import React from 'react';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

import { addRepFilter, removeRepFilter }  from '../../../../actions';

class BranchSelectionComponent extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            branches: undefined,
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if(this.props.reps != null && !this.state.branches){
            try {
                const branchNameReducer = (prevValue, currentValue) => { 
                    if(currentValue.branchName != null && !prevValue.includes(currentValue.branchName))
                        return [...prevValue, currentValue.branchName];
                    return prevValue;
                };
                this.setState({ branches: this.props.reps.reduce(branchNameReducer, []) });
            } catch (error) {
                console.error(error);
            }
        }
    }

    onChange = index => {
        if(index == 0){
            this.props.removeFilter();
            return;
        }
        this.props.addFilter((reps) => this.filter(reps, this.state.branches, index));
    }

    filter = (reps, branches, index) => {
        const branch = branches[index - 1];
        return reps.filter(rep => rep.branchName === branch);
    }

    mapOptions = branches => {
        return [ <option key={null} value={null} disabled={true} hidden={true} selected={true}>Select branch</option>,
            <option key={0} value={0}>None</option>,
            ...branches.map((branch, index) => {
                return (<option key={branch} value={index + 1}>
                    {branch}
                </option>)
            }).sort( (a, b) => a.props.children.localeCompare(b.props.children)),
        ];
    }

    render = () => {
        const { branches } = this.state;
        const { formGroupStyle } = this.props;
        return (
            branches && 
                <Form.Group
                    as={Col}
                    sm={12}
                    lg={6}
                    style={formGroupStyle}
                    controlId="branchSelection"
                    onChange={ (e) => { const { target: { value } } = e; this.onChange(value) } }
                >
                    <Form.Label>Filter by branch:</Form.Label>
                    <Form.Control as="select">
                        { this.mapOptions(branches) }
                    </Form.Control>
                </Form.Group>
            || null
        );
    }
}

const mapStateToProps = state => ({
    reps: state.reps.original
});

const mapDispatchToProps = dispatch => ({
    addFilter: fn => {
        dispatch(addRepFilter({ name: 'branchSelection', fn }));
    },
    removeFilter: () => {
        dispatch(removeRepFilter('branchSelection'));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(BranchSelectionComponent);
