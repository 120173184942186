import React from 'react';

const ProfileCard = props => {
    return(
        <div className='card card-hover-shadow-2x' style={{ ...props.style }}>
            { props.children }
        </div>
    );
}

export default ProfileCard;