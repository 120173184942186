import React from "react"; 
import Row from 'react-bootstrap/Row';

export default props => {
    const { trainerNames, stateName, emails, phoneNumbers } = props;
    return(
        <div style={ { width: '200px' } }>
            <Row style={ { margin: 0 } }>
                <div>
                    <span style={{ fontWeight: 'bold' }}>Trainer Name(s):</span> 
                    { trainerNames.reduce( (acum, value) => acum + ', ' + value) }
                </div>
                <div>
                    <span style={{ fontWeight: 'bold' }}>State Name:</span> { stateName }
                </div>
                <div>
                    <span style={{ fontWeight: 'bold' }}>Email(s):</span> {
                        emails.map( email => <React.Fragment key={email}><a href={"mailto:" + email }>{email}</a><br/></React.Fragment>)
                    }
                </div>
                <div>
                    <span style={{ fontWeight: 'bold' }}>Phone Number(s):</span> 
                    { phoneNumbers.reduce( (acum, value) => acum + ', ' + value) }
                </div>
            </Row>
        </div>
    );
}