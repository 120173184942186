import React from 'react';

import { useOktaAuth } from '@okta/okta-react';

function Login(){

    const { oktaAuth } = useOktaAuth();

    React.useEffect( () => {
        oktaAuth.signInWithRedirect();
    }, [] );

    return (
        null
    );
};

export default Login;